// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList, ListRenderItemInfo, Text } from 'react-native';
import styled from 'styled-components/native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import AsyncStorage from '@react-native-async-storage/async-storage';

// components
import {
  CustomText,
  SpacerColumn,
  SimpleButton,
  SmallButton,
  Heading,
  CustomIcon,
  AnimationRotateLoop,
} from '@components/atoms';

// misc
import { TabNavigatorParamList } from '@utils/navigation';
import { DashboardItems } from '@navigation/stacks/DashboardStacks';
import { useGetNews } from '@api/useGetNews';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectDeliveriesByStatus } from './selectors';
import { useGetInstructorDeliveries } from '@api/useGetInstructorDeliveries';
import { deliveriesSlice } from './slice';
import { useUser } from '@context/UserProvider';
import { DeliveryDatum } from './types';
import { NewsType } from '@api/types';
import { formatDateToSimple } from '@utils/date';
import { APP_VERSION_KEY } from '@utils/keys';
import { clearCache } from '@utils/reload';
import alert from '@utils/alert';
import { useSubmitAllDeliveries } from '@hooks/useSubmitAllDeliveries';
import { showMessage } from 'react-native-flash-message';

type DetailsType = {
  title: string;
  data: DeliveryDatum[] | NewsType[];
  type: 'news' | 'inProgressDeliveries' | 'upcomingDeliveries';
  moreTitle?: string;
  onPressMore?: () => void;
};

export const DashboardScreen = () => {
  // variables
  const { user } = useUser();
  const [isRefreshInProgress, setIsRefreshInProgress] = useState(false);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const route = useRoute();
  const { submit } = useSubmitAllDeliveries({
    onStart: () => {
      setIsRefreshLoading(true);
    },
    onSuccess: () => {
      refetchNews();
      refetchDeliveries();
      if (
        route &&
        route?.params &&
        route?.params?.goToViewRider &&
        route?.params?.deliveryId &&
        route?.params?.courseId
      ) {
        navigate('home', {
          screen: 'allRiders',
          params: {
            deliveryId: route?.params?.deliveryId,
            courseId: route?.params?.courseId,
            isClearRiders: true,
          },
        });
      }
    },
  });

  const { data, isFetching: isFetchingNews, refetch: refetchNews } = useGetNews();
  const dispatch = useAppDispatch();
  const { isFetching: isFetchingDeliveries, refetch: refetchDeliveries } =
    useGetInstructorDeliveries({
      onSuccess: async data => {
        const currentAppVersion = await AsyncStorage.getItem(APP_VERSION_KEY);

        if (!currentAppVersion) {
          await AsyncStorage.setItem(APP_VERSION_KEY, data.SystemData.appVersion);
        } else if (data.SystemData.appVersion !== currentAppVersion) {
          alert(
            'App Update Available!',
            'New app version is available, we recommend to update it right away.',
            [
              {
                text: 'Update',
                onPress: async () => {
                  await AsyncStorage.setItem(APP_VERSION_KEY, data.SystemData.appVersion);
                  clearCache(true);
                  location.reload();
                },
              },
              {
                text: 'Cancel',
                onPress: () => console.log('Cancel Pressed'),
                style: 'cancel',
              },
            ],
          );
        }

        dispatch(deliveriesSlice.actions.setDeliveries({ data }));
      },
    });

  useEffect(() => {
    if (route && route?.params && route?.params?.refreshData) {
      submit();
    }
  }, [route?.params]);

  useEffect(() => {
    if (isFetchingDeliveries && isFetchingNews && isRefreshLoading) {
      setIsRefreshInProgress(true);
    } else if (isRefreshInProgress && !isFetchingDeliveries && !isFetchingNews) {
      setIsRefreshLoading(false);
      dispatch(deliveriesSlice.actions.clearSavedDeliveries());
      showMessage({
        type: 'info',
        message:
          'All your saved data of deliveries has been pushed to server and cleared from local storage.',
        duration: 3000,
      });
    }
    if (!isFetchingDeliveries && !isFetchingNews && !isRefreshLoading) {
      getNavigationPath();
    }
  }, [isFetchingDeliveries, isFetchingNews, isRefreshInProgress, isRefreshLoading]);

  const getNavigationPath = () => {
    const navigationUrl = sessionStorage.getItem('LockScreenUrl');
    if (!navigationUrl) {
      return;
    }
    sessionStorage.removeItem('LockScreenUrl');
    const paramString = navigationUrl.split('?')[1];
    const pathString = navigationUrl.split('?')[0];
    const subPathString = pathString.split('authorised/')[1];
    const navigationPath = subPathString.split('/')[0];
    const screenPath = subPathString.split('/')[1];
    const queryString = new URLSearchParams(paramString);
    const paramsObj = {};
    for (const pair of queryString.entries()) {
      paramsObj[pair[0]] = parseInt(pair[1]).toString() !== 'NaN' ? parseInt(pair[1]) : pair[1];
    }
    if (
      navigationPath &&
      screenPath &&
      Object.keys(paramsObj).length > 0
      // DashboardItems.findIndex(dashboardItem => dashboardItem.name === screenPath) >= 0
    ) {
      navigate(navigationPath, { screen: screenPath, params: paramsObj });
    } else if (navigationPath && screenPath) {
      navigate(navigationPath);
      setTimeout(() => {
        navigate(screenPath);
      }, 50);
      // navigate(navigationPath, { screen: screenPath });
    } else if (navigationPath) {
      navigate(navigationPath);
    }
  };

  const inProgressDeliveries = useAppSelector(state =>
    selectDeliveriesByStatus(state, 'in_progress'),
  );

  const upcomingDeliveries = useAppSelector(state =>
    selectDeliveriesByStatus(state, 'not_started'),
  );

  const { navigate } = useNavigation<NativeStackNavigationProp<TabNavigatorParamList>>();
  const dashboardDetails: DetailsType[] = useMemo(
    () => [
      {
        title: 'News:',
        data: data ? data?.slice(0, 3) : [],
        moreTitle: 'All News',
        onPressMore: () => navigate('home', { screen: 'news' }),
        type: 'news',
      },
      {
        title: 'Deliveries In Progress:',
        data: inProgressDeliveries ? inProgressDeliveries?.slice(0, 3) : [],
        moreTitle: 'All Deliveries',
        onPressMore: () => navigate('home', { screen: 'deliveries' }),
        type: 'inProgressDeliveries',
      },
      {
        title: 'Upcoming Deliveries:',
        data: upcomingDeliveries ? upcomingDeliveries?.slice(0, 3) : [],
        moreTitle: 'All Deliveries',
        onPressMore: () => navigate('home', { screen: 'deliveries' }),
        type: 'upcomingDeliveries',
      },
    ],
    [data, inProgressDeliveries, upcomingDeliveries],
  );

  // hooks

  // functions

  // returns
  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<(typeof dashboardDetails)[0]>) => (
      <ItemContainer>
        <CustomText textAlign="center" color="primary" size={18} font="bodyBold">
          {item.title}
        </CustomText>
        <SpacerColumn size={1} />

        {item.data.map((i, index) => (
          <React.Fragment key={index}>
            <SimpleButton
              onPress={
                i?.deliveries?.id
                  ? () =>
                      navigate('home', {
                        screen: 'deliverySummary',
                        params: { deliveryId: i?.deliveries?.id },
                      })
                  : item.type === 'news' &&
                    (() =>
                      navigate('home', {
                        screen: 'news',
                        params: { id: i.id },
                      }))
              }
              key={i?.deliveries?.hostName || i?.title}
              title={
                `${i?.deliveries?.hostName || i?.title}` +
                // (item.type === 'news'
                //   ? ` - ${formatDateToSimple(i.date, '/', null)}`
                //   : item.type === 'upcomingDeliveries'
                //   ? ` - ${formatDateToSimple(i?.deliveries?.startDate)}`
                //   : '')
                (item.type === 'news'
                  ? ` - ${formatDateToSimple(i.date, '/', null)}`
                  : ` - ${formatDateToSimple(i?.deliveries?.startDate)}`)
              }
              courses={i.courses?.map(function (item) {
                  return item['courseLabelShortName'];
                })
                .join(', ')}
              shortDescription={
                  `${i?.deliveries?.shortDescription || ''}`
                }
            />
            <SpacerColumn size={1} />
          </React.Fragment>
        ))}

        {item.moreTitle && <SmallButton title={item.moreTitle} onPress={item.onPressMore} />}
      </ItemContainer>
    ),
    [],
  );

  return (
    <Container>
      <Heading
        LeftComponent={() => (
          <>
            <AnimationRotateLoop
              isRunning={isRefreshLoading || isFetchingDeliveries || isFetchingNews}>
              {/* <CustomIcon size={24} name="refresh-ccw" color="text" onPress={() => submit()} /> */}
              <CustomIcon size={24} name="refresh" type='material' color="text" onPress={() => submit()} />
            </AnimationRotateLoop>
            <Text
              style={{ width: '5em', fontWeight: 'bold', marginLeft: 5 }}
              onPress={async () => {
                submit();
              }}>
              Refresh Data
            </Text>
          </>
        )}
        title={`Logged in as: ${user?.first_name} ${user?.last_name}`}
      />

      <div style={{ height: '100%', overflow: 'auto' }}>
        <Content>
          <FlatList
            data={dashboardDetails}
            keyExtractor={item => item.title}
            renderItem={renderItem}
          />
        </Content>
      </div>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(({ theme: { layout } }) => ({
  paddingHorizontal: layout.contentPadding,
}));

const ItemContainer = styled.View(({ theme: { layout } }) => ({
  paddingVertical: layout.padding_x1,
}));
