// libraries
import React from 'react';
import styled from 'styled-components/native';
import { genericStyles } from '@styles/genericStyles';

// components
import { CustomPressable, CustomPressableProps } from './CustomPressable';
import { CustomText } from './CustomText';
import { CustomIcon } from '@components/atoms';

// types
export interface SimpleButtonProps extends CustomPressableProps {
  title: string;
  courses?: string;
  shortDescription?: string;
}

export const SimpleButton = ({ title, courses, shortDescription, ...restProps }: SimpleButtonProps) => {
  // renders
  return (
    <Button {...restProps}>
      <CustomText font="bodyBold">{title}</CustomText>
      <CustomText font="bodyBold">{shortDescription}</CustomText>
      {courses && (
        <CustomText size={16} font="bodyBold">
          <CustomIcon
            name="bicycle-outline"
            type="ionic"
            size={22}
            color="text"
            style={{ marginRight: 10 }}
          />
          {courses}
        </CustomText>
      )}
    </Button>
  );
};

const Button = styled(CustomPressable)(({ theme: { colors, layout } }) => ({
  ...genericStyles.jcAiCenter,
  backgroundColor: colors.cornflowerBlue,
  padding: layout.padding_x1,
}));
