// libraries
import React, { useCallback, useMemo } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { useWindowDimensions } from 'react-native';

// components
import { CustomText, CustomIcon } from '@components/atoms';

// misc
import { ColorPalette } from '@styles/types';
import { genericStyles } from '@styles/genericStyles';
import { DeliveryStatusType } from '../types';
import { View } from 'react-native-animatable';

// types
export type DeliveryItemProps = {
  title: string;
  status: DeliveryStatusType;
  courses?: string;
  shortDescription?: string;
  onPress?(): void;
};

export const DeliveryItem = ({ title, status, onPress, courses, shortDescription }: DeliveryItemProps) => {
  // variables
  const { width } = useWindowDimensions();
  const { layout } = useTheme();
  const fontSize = useMemo(() => {
    return (width - (layout.contentPadding * 2 + layout.padding_x1 * 2)) / title.length + 4;
  }, []);

  // renders
  const RenderBadge = useCallback(() => {
    switch (status) {
      case 'complete':
        return <ColorBadge color="success" />;
      case 'in_progress':
        return <ColorBadge color="warning" />;
      default:
        return <ColorBadge color="error" />;
    }
  }, []);

  const titleText = title.split(' - ')[0];
  const titleDate = title.split(' - ')[1];
  return (
    <>
      <Container onPress={onPress} isDisabled={status === 'complete'}>
        <CustomText
          // size={fontSize > 16 ? 16 : fontSize}
          size={16}
          style={{ padding: layout.padding_x1, overflow: 'auto', whiteSpace: 'nowrap' }}
          font="bodyBold">
          {titleText}
        </CustomText>
        <CustomText
          size={16}
          style={{ padding: layout.padding_x1, minWidth: '6em', marginLeft: 'auto' }}
          font="bodyBold">
          {titleDate}
        </CustomText>
        <RenderBadge />
      </Container>
      <Container onPress={onPress} isDisabled={status === 'complete'}>
        <CustomText
          size={16}
          style={{ paddingLeft: 5, overflow: 'auto', whiteSpace: 'nowrap' }}
          font="bodyBold">
          <CustomIcon
            name="bicycle-outline"
            type="ionic"
            size={22}
            color="text"
            style={{ marginRight: 10 }}
          />
          {courses}
        </CustomText>
        <CustomText
          size={16}
          style={{ padding: layout.padding_x1, minWidth: '6em', marginLeft: 'auto' }}
          font="bodyBold">
          {shortDescription}
        </CustomText>
        <RenderBadge />
      </Container>
    </>
  );
};

const Container = styled.Pressable<{ isDisabled: boolean }>(
  ({ theme: { colors }, isDisabled }) => ({
    backgroundColor: isDisabled ? colors.mercury : colors.cornflowerBlue,
    height: 30,
    alignItems: 'center',
    ...genericStyles.rowWithCenterAndSB,
  }),
);

const ColorBadge = styled.View<{ color: ColorPalette }>(({ theme: { colors }, color }) => ({
  backgroundColor: colors[color],
  width: 25,
  height: '100%',
}));
