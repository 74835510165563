// libraries
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

// components
import { CustomIcon, CustomText, DesigningButton, DivRow, Heading, SpacerColumn, SpacerRow, AnimationRotateLoop } from "@components/atoms";

// misc
import { DashboardStackParamList } from '@utils/navigation';
import { genericStyles } from '@styles/genericStyles';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { useGetCourseTrainingChecklist } from '@api/useGetCourseTrainingChecklist';
import cache from '@utils/cache';
import { DELIVERY_SELECTED_RIDERS_KEY } from '@utils/keys';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import { deliveriesSlice } from '@screens/dashboard/slice';
import { ChecklistKeys } from '@components/molecules/ChecklistKeys';
import { FullScreenLoader } from '@components/molecules';
import { selectSavedCourse } from '@screens/dashboard/selectors';

export const TrainingChecklist = () => {
  // variables
  const dispatch = useAppDispatch();
  const [checkList, setCheckList] = useState([]);
  const [showChecklistPopup, setShowChecklistPopup] = useState(true);
  const [showFullName, setShowFullName] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showSpin, setShowSpin] = useState(false);
  const [showTrainingCompletedPoup, setShowTrainingCompletedPoup] = useState(false);
  const {
    params: { deliveryId, courseId },
  } = useRoute<RouteProp<DashboardStackParamList, 'training'>>();
  const { setOptions, replace, navigate } =
    useNavigation<NativeStackNavigationProp<DashboardStackParamList>>();
  const { riders, delivery, course, riderIds } = useGetDeliveryCourseAndRiders();
  const { submit } = useSubmitSavedDelivery('Training completed successfully!', {
    onSuccess: () => {
      console.log('Training completed');
      setShowTrainingCompletedPoup(true);
    },
  });

  const savedCourse = useAppSelector(state => selectSavedCourse(state, delivery.id, course.id));

  // hooks
  useEffect(() => {
    window.addEventListener('popstate', function (event) {
      getChecklist();
    });
  }, []);

  // const [isLocalSurveyComplete, setIsLocalSurveyComplete] = useState(false);
  const getChecklist = async () => {
    if (courseId) {
      const data = await useGetCourseTrainingChecklist(courseId);
      let outcomeSize = 0;
      if (
        data &&
        data.riderOutcome &&
        data.riderOutcome.length &&
        data.riderOutcome[0] &&
        data.riderOutcome[0].outcome
      ) {
        outcomeSize = data.riderOutcome[0].outcome.length;
      }
      const selectedRiders = cache.get(DELIVERY_SELECTED_RIDERS_KEY);
      const selectedRidersList = await selectedRiders.then(value => {
        return value[deliveryId][courseId];
      });

      // const isLocalSurveyComplete =
      //   (savedCourse?.training_survey_riders && checkSavedCourseRidersList(selectedRidersList, savedCourse.training_survey_riders)) &&
      //   (savedCourse?.training_survey && checkOptionTotals(savedCourse.training_survey, selectedRidersList));

      // setIsLocalSurveyComplete(!!isLocalSurveyComplete);

      const checklistData = [];
      let selectedRider = {};
      const defaultArr = Array(outcomeSize);
      defaultArr.fill({ text: null });
      selectedRidersList.forEach((rider: any) => {
        selectedRider = data.riderOutcome.find(riderApiData => riderApiData.riderId === rider.id);
        if (selectedRider) {
          checklistData.push(selectedRider);
        } else {
          checklistData.push({
            riderId: rider.id,
            riderName: rider.name,
            hasCompletedCourse: 0,
            hasCompletedSurvey: 0,
            feedbackCompletedFlag: 0,
            outcome: defaultArr,
          });
        }
      });
      setCheckList(checklistData);
    }
  };
  if (courseId && checkList && checkList.length <= 0) {
    getChecklist();
  }

  const checkSavedCourseRidersList = (
    selectedRidersList: any[],
    trainingSurveyRiders: number[],
  ): boolean => {
    // Check if all selected rider IDs are included in the training survey riders
    return selectedRidersList.every(rider => trainingSurveyRiders.includes(rider.id));
  };

  const checkOptionTotals = (trainingSurvey, selectedRiderList) => {
    // Iterate through each question in the training survey
    for (const question of trainingSurvey) {
      let totalOptions = 0;

      // Iterate through each option in the question
      for (const option of question.option) {
        // Sum up the totals of all options
        totalOptions += option.total;
      }

      // Check if the total options equal the number of selected riders
      if (totalOptions !== selectedRiderList.length) {
        return false;
      }
    }

    // If all options add up to the number of selected riders for each question, return true
    return true;
  };

  const titleBlock = (
    <View style={genericStyles.rowWithCenterAndSB}>
      <DivRow style={{ margin: '1em' }}>
        {/* <SpacerRow size={2} /> */}
        {/* <CustomIcon
          type="material"
          name="cloud-download"
          color="text"
          onPress={getChecklist}
          size={30}
        /> */}
        <AnimationRotateLoop isRunning={showSpin}>
          <CustomIcon
            size={24}
            name="refresh"
            type="material"
            color="text"
            onPress={async () => {
              setShowSpin(true);
              await getChecklist();
              setTimeout(() => {
                setShowSpin(false);
              }, 2000);
            }}
          />
        </AnimationRotateLoop>
        <Text
          style={{ width: '5em', fontWeight: 'bold', marginLeft: 5 }}
          onPress={async () => {
            setShowSpin(true);
            await getChecklist();
            setTimeout(() => {
              setShowSpin(false);
            }, 2000);
          }}>
          Refresh Data
        </Text>
        <SpacerRow size={1} />
        <CustomText color="primary" size={20} font="bodyBold">
          Training Checklist
        </CustomText>
        <SpacerRow size={1} />
        {/* <ChecklistKeys /> */}
      </DivRow>
    </View>
  );

  const headerTextBlock = (
    <View style={{ margin: '1em' }}>
      <CustomText textAlign="left">
        The view below shows the status of completion for course outcomes, feedback and survey
        questions for your riders. Please complete any missing information before you can mark the
        course as complete.{' '}
        <CustomText style={{ fontWeight: 'bold' }}>A data connection is required</CustomText> to
        refresh data.
      </CustomText>
      <SpacerRow size={1} />
      {/* <CustomText textAlign="left">
        Please complete any missing information before you can mark the course as complete.
      </CustomText>
      <SpacerRow size={1} />
      <CustomText textAlign="left">
        A data connection is required so check that the Link server has received all of the data.
      </CustomText>
      <SpacerRow size={1} /> */}
      {/* <CustomText textAlign="left" style={{ fontWeight: 'bold' }}>Use the Key above for detail.</CustomText>
      <SpacerRow size={1} /> */}
    </View>
  );

  const nameStyle = {
    ...styles.cellName,
    ...(showFullName ? styles.fullName : styles.shortName),
  };

  const tableHeader = (
    <View style={styles.row}>
      <Text style={showFullName ? styles.fullName : styles.shortName}></Text>
      <Text style={styles.cellHeader}>C</Text>
      <Text style={styles.cellHeader}>F</Text>
      <Text style={styles.cellHeader}>S</Text>
      {checkList &&
        checkList.length &&
        checkList[0] &&
        checkList[0].outcome &&
        checkList[0].outcome.map((outcomeObj, index) => (
          <Text key={index} style={styles.cellHeader}>
            {index + 1}
          </Text>
        ))}
    </View>
  );

  const tableBody =
    checkList &&
    checkList.length &&
    checkList.map((checkListObj, indexChecklist) => (
      <View style={styles.row} key={indexChecklist}>
        <Text style={nameStyle} onPress={() => setShowFullName(!showFullName)}>
          {showFullName
            ? checkListObj.riderName
            : checkListObj.riderName
              .split(' ')
              .map(namePart => namePart[0])
              .join('')}
        </Text>
        <Text style={styles.cellBody}>
          {checkListObj.hasCompletedCourse ? (
            <CustomIcon type="material" name="done" color="success" size={30} />
          ) : (
            <CustomIcon type="material" name="close" color="error" size={30} />
          )}
        </Text>
        <Text style={styles.cellBody}>
          {checkListObj.feedbackCompletedFlag ? (
            <CustomIcon type="material" name="done" color="success" size={30} />
          ) : (
            <CustomIcon type="material" name="close" color="error" size={30} />
          )}
        </Text>
        <Text style={styles.cellBody}>
          {/* {checkListObj.hasCompletedSurvey || isLocalSurveyComplete ? ( */}
          {checkListObj.hasCompletedSurvey ? (
            <CustomIcon type="material" name="done" color="success" size={30} />
          ) : (
            <CustomIcon type="material" name="close" color="error" size={30} />
          )}
        </Text>
        {checkListObj &&
          checkListObj.outcome &&
          checkListObj.outcome.map((outcomeObj, index) => (
            <Text key={indexChecklist.toString() + index.toString()} style={styles.cellBody}>
              {outcomeObj.text ? (
                <CustomText color="primary" size={20} font="bodyBold">
                  {outcomeObj.text}
                </CustomText>
              ) : (
                <CustomIcon type="material" name="close" color="error" size={30} />
              )}
            </Text>
          ))}
      </View>
    ));

  let isCompleteTraining = true;
  if (checkList) {
    checkList.forEach(checkListItem => {
      if (checkListItem.feedbackCompletedFlag !== 1) {
        isCompleteTraining = false;
      }
      // if ((checkListItem.hasCompletedSurvey !== 1) && !isLocalSurveyComplete) {
        if ((checkListItem.hasCompletedSurvey !== 1)) {
        isCompleteTraining = false;
      }
      if (checkListItem && checkListItem.outcome) {
        checkListItem.outcome.forEach(outcomeItem => {
          if (outcomeItem.text === null) {
            isCompleteTraining = false;
          }
        });
      }
    });
  }

  // functions
  const onComplete = async () => {
    await riderIds.forEach(id => {
      dispatch(
        deliveriesSlice.actions.setSavedRiderCourse({
          riderId: id,
          deliveryId: delivery.id,
          courseId: course.id,
          checkComplete: true,
        }),
      );
    });
    setTimeout(() => {
      submit();
      setShowLoading(false);
    }, 1000);
  };

  if (showLoading) {
    return <FullScreenLoader loadingText="Please wait while we process your request and do not navigate away from the screen until the process is complete." />;
  }

  return (
    <Container>
      <Heading title={delivery.hostName} status={delivery.status} showSchoolIcon />

      <Content style={{ overflow: 'auto' }}>
        {titleBlock}
        {headerTextBlock}
        <View style={genericStyles.rowWithCenterAndSB}>
          <SpacerRow size={1} />
          <ChecklistKeys buttonName="VIEW CHECKLIST KEY" showPopup={showChecklistPopup} />
          <SpacerRow size={1} />
        </View>
        <View style={styles.table} id="checklistScroll" nativeID="checklistScroll">
          {tableHeader}
          {tableBody}
        </View>
        <View style={{ marginLeft: 10, marginRight: 10 }}>
          <SpacerColumn size={3} />
          <div>
            <div style={{ width: '180px', float: 'left', maxWidth: '45vw' }}>
              <DesigningButton
                title="Complete Training"
                width="180px"
                maxWidth="45vw"
                bgColor={!isCompleteTraining ? 'lightgray' : ''}
                onPress={async () => {
                  if (isCompleteTraining) {
                    setShowLoading(true);
                    setShowChecklistPopup(false);
                    await onComplete();
                  }
                }}
              />
            </div>
            <div style={{ width: '180px', float: 'right', maxWidth: '45vw' }}>
              <DesigningButton
                title="Go to Survey"
                width="180px"
                maxWidth="45vw"
                onPress={async () => {
                  navigate('trainingSurvey', { deliveryId: delivery.id, courseId: course.id });
                }}
              />
            </div>
          </div>
          <SpacerColumn size={2} />
          <div>
            <div style={{ width: '180px', float: 'left', maxWidth: '45vw' }}>
              <DesigningButton
                title="Go to Training"
                width="180px"
                maxWidth="45vw"
                onPress={() => {
                  localStorage.setItem('showActivity', 'true');
                  navigate('home', {
                    screen: 'training',
                    params: { deliveryId: deliveryId, courseId: courseId },
                  });
                }}
              />
            </div>
            <div style={{ width: '180px', float: 'right', maxWidth: '45vw' }}>
              <DesigningButton
                title="Go to Feedback"
                width="180px"
                maxWidth="45vw"
                onPress={() => {
                  navigate('instructorFeedback', { deliveryId: delivery.id, courseId: course.id });
                }}
              />
            </div>
          </div>
          <SpacerColumn size={2} />
        </View>
        {showTrainingCompletedPoup && (
          <View
            style={{
              width: '70vw',
              left: '16vw',
              top: '20vh',
              position: 'absolute',
              backgroundColor: 'lightgray',
              padding: '2em',
              border: '1px solid black',
              alignItems: 'center',
            }}>
            <Text style={{ fontSize: '1.5em', fontWeight: '500', textAlign: 'center' }}>
              Training Completed
            </Text>
            <SpacerColumn size={2} />
            <DesigningButton
              title="Close"
              width="80px"
              onPress={() => {
                navigate('home', {
                  screen: 'dashboard',
                  params: {
                    refreshData: true,
                    goToViewRider: true,
                    deliveryId: delivery.id,
                    courseId: course.id,
                  },
                });
                // history.back();
              }}
            />
          </View>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(() => ({
  flex: 1,
}));

const styles = StyleSheet.create({
  table: {
    borderWidth: 0,
    borderColor: 'black',
    marginBottom: 20,
    marginTop: 30,
    marginLeft: 10,
    marginRight: 10,
    overflowX: 'auto',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
  },
  fullName: {
    minWidth: 160,
    margin: 10,
  },
  shortName: {
    minWidth: 50,
    margin: 10,
  },
  cellName: {
    padding: 10,
    borderWidth: 0,
    height: 30,
    textAlign: 'left',
    fontSize: 14,
    color: 'black',
    backgroundColor: 'rgb(215, 247, 250)',
    fontWeight: 'bold',
    borderRadius: 6,
    lineHeight: 12,
  },
  cellBody: {
    width: 30,
    height: 30,
    margin: '10px',
    lineHeight: 30,
    maxWidth: 30,
    minWidth: 30,
    fontSize: 16,
    textAlign: 'center',
  },
  cellHeader: {
    backgroundColor: 'black',
    color: 'white',
    width: 30,
    height: 30,
    margin: '10px',
    lineHeight: 30,
    borderRadius: 20,
    maxWidth: 30,
    minWidth: 30,
    fontSize: 16,
    textAlign: 'center',
  },
});
